export const Year2024 = [
  {
    id: 1,
    when: "January 2024",
    innerLink: "선린인터넷고등학교 신입생 특별교육",
    content: " 수료",
    href: "https://github.com/eungyolee/sunrin-c-project"
  },
  {
    id: 2,
    when: "January 2024",
    innerLink: "2025년 카운트다운",
    content: " 완성",
    href: "https://eungyolee.tech/2025-countdown"
  }
];

export const Year2023 = [
  {
    id: 1,
    when: "November 2023",
    innerLink: "",
    content: "선린인터넷고등학교 소프트웨어과 합격",
    href: ""
  },
  {
    id: 2,
    when: "August 2023",
    innerLink: "염경중학교 학생기자단 홈페이지",
    content: " 완성",
    href: "https://ymgng-reporter.github.io/"
  },
  {
    id: 3,
    when: "July 2023",
    innerLink: "",
    content: "선린인터넷고등학교 특별교육 프로그래밍 과정 수료",
    href: ""
  },
  {
    id: 4,
    when: "February 2023",
    innerLink: "구름 하이스쿨 알고리즘 캠프",
    content: " 수료",
    href: "https://swcamp-highschool.oopy.io/"
  },
  {
    id: 5,
    when: "January 2023 ~ 현재",
    innerLink: "Project_IO Official Team",
    content: " 소속 Front-End 개발자",
    href: "https://github.com/project-official"
  }
];

export const Year2022 = [
  {
    id: 1,
    when: "November 2022 ~ April 2023",
    innerLink: "WADE Studio",
    content: " 소속 견습생",
    href: "https://github.com/wadestudio"
  },
  {
    id: 2,
    when: "July 2022",
    innerLink: "염경중학교 위키",
    content: " 개발",
    href: "https://ymgng-wiki.eungyolee.tech"
  },
  {
    id: 3,
    when: "June 2022",
    innerLink: "역사 문제 사이트",
    content: " 개발",
    href: "https://history-exam.eungyolee.tech"
  }
];

export const Year2021 = [
  {
    id: 1,
    when: "September 2021",
    innerLink: "Momentum 클론",
    content: " 코딩",
    href: "https://eungyolee.tech/momentum-clone"
  },
  {
    id: 2,
    when: "May 2021",
    innerLink: "다음 생 국적 테스트",
    content: " 개발",
    href: "https://github.com/eungyolee/country-test"
  },
];

// export const Year2020 = [
//   {
//     id: 1,
//     when: "July 2020",
//     innerLink: "Github",
//     content: " 가입",
//     href: "https://github.com/eungyolee"
//   },
//   {
//     id: 2,
//     when: "April 2020",
//     innerLink: "",
//     content: "코딩 시작",
//     href: ""
//   }
// ];